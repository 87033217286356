<template>
  <div v-if="isClientLoadingProperly" class="d-flex justify-content-center align-items-center"
    style="min-height: 60vh;">
    <Spinner />
  </div>
  <div v-else>
    <div>
      <b-row v-if="client" class="content-header">
        <b-col class="content-header-left mb-2" cols="12" md="9">
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <h2 class="content-header-title float-left pr-1 mb-0 text-white">
                <b-avatar class="mr-1" size="32" :src="client.logo" :text="avatarText(client.name)"
                  :variant="client.logo ? `white` : `light-primary`" />
                {{ client.name }}
              </h2>
              <div class="breadcrumb-wrapper breadcrumb-container">
                <b-breadcrumb>
                  <b-breadcrumb-item to="/">
                    <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
                  </b-breadcrumb-item>
                  <b-breadcrumb-item v-for="item in breadcrumbs" :key="item.text" :active="item.active" :to="item.to"
                    class="text-white">
                    {{ item.text }}
                  </b-breadcrumb-item>
                </b-breadcrumb>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-alert :show="!client" variant="danger">
        <h4 class="alert-heading">Error fetching Client data</h4>
        <div class="alert-body">
          {{ errorMessage }}. Check
          <b-link class="alert-link" :to="{ name: 'client-list' }">
            Clients List
          </b-link>
          for other clients.
        </div>
      </b-alert>
      <b-overlay :show="isFetchingAnalytics" rounded="sm">
        <div v-if="isSomethingWentWrong" class="d-flex justify-content-center align-items-center"
          style="min-height: 60vh;">
          <h1 style="color:white">Please select a different account to view the data.</h1>
        </div>
        <div v-else>
          <b-row class="match-height">
            <b-col cols="4">
              <date-selector @fetchNewData="updateDateRange" />
            </b-col>
            <b-col cols="8">
              <b-card no-body class="card-statistics date-wise-data-card">
                <b-card-body class="statistics-body">
                  <div style="align-items:center; justify-content:center">
                    <div v-if="this.userTypeData.length === 0" class="spinnerCentered">
                      <span>No Data Found!</span>
                    </div>
                    <div v-else style="display: flex;justify-content: center;align-items: center;height: 100%;
}">
                      <apexchart type="area" height="200" width="700" :options="chartOptions" :series="series">
                      </apexchart>

                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-card class="
tabs-container screenAlignment">
            <b-tabs fill>
              <b-tab title="Overview" active @click="changeAnalyticalTab('Overview')"></b-tab>
              <b-tab title="Campaign Overview" @click="changeAnalyticalTab('CampaignOverview')"> </b-tab>
            </b-tabs>
          </b-card>

          <b-row class="match-height" v-if="this.selectedAnalyticalTab === 'Overview'">
            <b-col cols="4">
              <b-card>
                <b-card-title>
                  <h3 class="app-title sm">Top 10 Active Days</h3>
                </b-card-title>
                <chartjs-component-horizontal-bar-chart :height="400" :data="sessionsByCity"
                  :options="chartjsData.horizontalBarChart.options" />
              </b-card>
            </b-col>
            <b-col cols="4">
              <b-card>
                <b-card-title>
                  <h3 class="app-title sm">Sessions By Country (Top 10)</h3>
                </b-card-title>
                <chartjs-component-horizontal-bar-chart :height="400" :data="sessionsByCounty"
                  :options="chartjsData.horizontalBarChart.options" />
              </b-card>
            </b-col>
            <b-col cols="4">
              <b-card>
                <b-card-title>
                  <h3 class="app-title sm">Sessions by device</h3>
                </b-card-title>
                <app-echart-doughnut :options="sessionsByDevice.options" :series="sessionsByDevice.series" />
              </b-card>
            </b-col>
          </b-row>
          <b-card class="screenAlignment" v-if="this.selectedAnalyticalTab === 'CampaignOverview'">
            <b-row>
              <b-col cols="12">
                <div class="activity-trackor">
                  <div class="log-table-container">
                    <div class="search-activity-container mb-1">
                      <input class="input-search-activity" placeholder="Search By Session Campaign"
                        v-model="searchAnalytics" />
                      <b-button style="margin-top: -10px; margin-right:-10px" variant="flat-primary"
                        @click="exportSessionCampaignDataData">
                        <feather-icon size="25" icon="DownloadIcon"></feather-icon>
                      </b-button>
                    </div>
                    <div style="width: 100%;
                    overflow-x: auto;
                    -webkit-overflow-scrolling: touch;">
                    <table  class="log-table full-width">
                      <thead>
                        <tr>
                          <th>Session Campaign</th>
                          <th>Session Manual Ad Content</th>
                          <th>Session Manual Term</th>
                          <th>Checkouts</th>
                          <th>Purchases</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="log in this.paginatedActivities" :key="log.searchAnalytics">

                          <td class="log-date">
                            <span class="currency">{{ log.sessionCampaignName }}</span>
                          </td>
                          <td class="log-date">
                            <span class="currency">{{ log.sessionManualAdContent }}</span>
                          </td>
                          <td class="log-date">
                            <span class="currency">{{ log.sessionManualTerm }}</span>
                          </td>
                          <td class="log-date">
                            <span class="currency">{{ log.checkouts }}</span>
                          </td>
                          <td class="log-date">
                            <span class="currency">{{ log.totalPurchasers }}</span>
                          </td>
                          <td class="log-date">
                            <span class="currency">{{ log.totalRevenue }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  </div>
                  <b-pagination v-if="this.sessionCampaignData.length > 0" v-model="currentPage" :total-rows="totalRows"
                    :per-page="pageLength" first-number last-number align="right" prev-class="prev-item"
                    next-class="next-item" class="mt-1 mb-0" @change="pageChanged">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-overlay>
    </div>
  </div>

</template>

<script>
import {
  BCardHeader,
  BRow,
  BCol,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BAlert,
  BLink,
  BAvatar,
  BCard,
  BBadge,
  BButton,
  BTable,
  BSpinner,
  BFormGroup,
  BFormInput,
  BCardBody,
  BCardText,
  BOverlay,
  BMedia,
  BMediaAside,
  BMediaBody,
  BTabs,
  BTab,
  BPagination
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import ClientDetail from "./UserViewUserInfoCard.vue";
import MetricDimensionSelector from "./MetricsDimensionSelector.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import millify from "millify";
import moment from "moment";
import LineChart from "../Charts/ChartjsLineChart.vue";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DateSelector from "./DateSelector.vue";
import ChartjsComponentHorizontalBarChart from "./charts-components/ChartjsComponentHorizontalBarChart.vue";
import chartjsData from "./chartjsData";
import Spinner from "@/components/client/Spinner.vue";

export default {
  data() {
    return {
      isSomethingWentWrong: false,
      searchAnalytics: "",
      sessionCampaignData: [],
      filteredActivityLength: 0,
      currentPage: 1,
      pageLength: 10,
      selectedAnalyticalTab: "Overview",
      isClientLoadingProperly: false,
      series: [
        {
          name: 'Active Users',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          id: 'active-users-chart',
          toolbar: {
            show: false
          },
          dataLabels: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Active Users'
          }
        },
        colors: ['#3804fc'],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.2,
            stops: [0, 100]
          }
        }
      },
      topCountry: "",
      topCountryCount: 0,
      topDevices: "",
      topDevicesCount: 0,
      topCity: "",
      topCityCount: 0,
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      client: {
        integrations: {
          google: null,
          facebook: null,
        },
      },
      errorMessage: "",
      isFetchingAccounts: false,
      dateRange: [],
      isFetchingAnalytics: true,
      userTypeData: {},
      sessionDevices: {},
      sessionCounties: [],
      pagePathVisits: {},
      staticCardData: [],
      chartjsData,
    };
  },
  components: {
    BCardHeader,
    BRow,
    BCol,
    BCardTitle,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BAlert,
    BLink,
    BAvatar,
    ClientDetail,
    MetricDimensionSelector,
    ToastificationContent,
    BCard,
    BBadge,
    BButton,
    flatPickr,
    BTable,
    BSpinner,
    LineChart,
    BFormGroup,
    BFormInput,
    DateSelector,
    BCardBody,
    BMedia,
    BMediaBody,
    BCardText,
    BOverlay,
    AppEchartDoughnut,
    ChartjsComponentHorizontalBarChart,
    BMediaAside,
    apexchart: VueApexCharts,
    Spinner,
    BTabs,
    BTab,
    BPagination
  },
  methods: {
    updategraph(){
      this.chartOptions = {
        chart: {
          id: 'active-users-chart',
          toolbar: {
            show: false
          },
          dataLabels: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Active Users'
          }
        },
        colors: ['#3804fc'],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.2,
            stops: [0, 100]
          }
        }
      }
    },
    exportSessionCampaignDataData() {
      // Map through the session data to format it for export
      const formattedData = this.sessionCampaignData.map((item) => ({
        sessionManualAdContent: item.sessionManualAdContent,
        sessionCampaignName: item.sessionCampaignName,
        sessionManualTerm: item.sessionManualTerm,
        sessions: item.sessions,
        checkouts: item.checkouts,
        totalRevenue: item.totalRevenue,
        totalPurchasers: item.totalPurchasers,
      }));

      // Convert the data to CSV format
      const csvContent = this.convertToCSV(formattedData);

      // Create a Blob and trigger the download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "session_data.csv");
      link.click();
    },

    convertToCSV(data) {
      // Get the headers from the first object
      const headers = Object.keys(data[0]);

      // Create rows for CSV
      const rows = data.map((item) =>
        headers.map((header) => JSON.stringify(item[header] || "")).join(",")
      );

      // Combine headers and rows into a CSV string
      return [headers.join(","), ...rows].join("\n");
    }
    ,
    pageChanged(value) {
      this.currentPage = value;
    },
    changeAnalyticalTab(tab) {
      this.selectedAnalyticalTab = tab
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    resolveClientStatusVariant(status) {
      return status ? "success" : "danger";
    },
    resolveIntegrationVariant(integrations, provider) {
      return integrations[provider] ? "success" : "danger";
    },
    getData(startDate, endDate) {
      const dates = this.dateRange;
      if (!startDate || !endDate) {
        startDate = dates[0];
        endDate = dates[1];
      }
      const clientID = this.client._id;
      this.isFetchingAnalytics = true;
      this.isSomethingWentWrong = false
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/analytics/get-data/google`,
          {
            clientID,
            startDate,
            endDate,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isFetchingAnalytics = false;
          this.isSomethingWentWrong = false
          this.userTypeData = response && response.data && response.data.userTypes
          this.sessionDevices = response && response.data && response.data.sessionDevices
          this.staticCardData = response && response.data && response.data.staticCardData
          this.sessionCampaignData = response && response.data && response.data.campaignData
          this.pagePathVisits = response && response.data && response.data.userTypes
          this.sessionCounties = response && response.data && response.data.sessionCountries
          this.data = response.data;
          this.prepareChartData();
        })
        .catch((error) => {
          this.isFetchingAnalytics = false;
          this.isSomethingWentWrong = true;
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error);
        });
    },
    formatSingleDate(date) {

      // If the date is a string in the format 'YYYYMMDD', convert it to 'YYYY-MM-DD'
      if (typeof date === 'string' && date.length === 8) {
        // Convert the string to a date in the format YYYY-MM-DD
        date = date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8);
      }

      // Now, create a Date object
      const d = new Date(date);

      // Ensure that the date is valid
      if (isNaN(d)) {
        return null;  // Handle invalid date
      }

      // Format the date as YYYY-MM-DD
      const year = d.getFullYear();
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const day = d.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    getClient() {
      const slug = this.$route.params.slug;
      this.isClientLoadingProperly = true
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = response.data.client;
          this.breadcrumbs.push({
            text: response.data.client.name,
            to: `/client/${response.data.client.slug}`,
          });
          this.breadcrumbs.push({
            text: "Analytics Dashboard",
            to: "/client",
            active: true,
          });
          this.isPublicDashboard = response.data.client.publicDashboard;
          this.publicDashboardURL = `${process.env.VUE_APP_URL}/client/${response.data.client.slug}/dashboard/public`;
          this.getData();
          this.isClientLoadingProperly = false
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data);
          this.client = null;
          this.errorMessage = error.response.data.error;
          this.isClientLoadingProperly = false
        });
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    getFormatedValue(value, type) {
      if (type === "INTEGER") {
        return millify(value);
      } else if (type === "PERCENT") {
        value = parseFloat(value);
        return value.toFixed(2) + "%";
      } else if (type === "TIME") {
        var dur = moment.duration(parseInt(value, 10), "seconds");
        var minutes = dur.minutes();
        var seconds = dur.seconds();
        return `${minutes}m ${seconds}s`;
      } else {
        return value;
      }
    },
    getFormattedName(name) {
      if (name === "ga:sessions") {
        return "Sessions";
      } else if (name === "ga:pageviews") {
        return "Page Views";
      } else if (name === "ga:avgSessionDuration") {
        return "Avg. Session Duration";
      } else if (name === "ga:bounceRate") {
        return "Bounce Rate";
      }
    },
    getIconVariant(name) {
      if (name === "ga:sessions") {
        return {
          icon: "SmartphoneIcon",
          variant: "light-warning",
        };
      } else if (name === "ga:pageviews") {
        return {
          icon: "FileIcon",
          variant: "light-info",
        };
      } else if (name === "ga:avgSessionDuration") {
        return {
          icon: "ClockIcon",
          variant: "light-pink",
        };
      } else if (name === "ga:bounceRate") {
        return {
          icon: "TrendingDownIcon",
          variant: "light-danger",
        };
      }
    },
    updateDateRange(startDate, endDate) {
      this.getData(startDate, endDate);
    },
    prepareChartData() {
      const sortedData = this.userTypeData.sort((a, b) => new Date(b.date) - new Date(a.date));
      this.series[0].data = sortedData.map(item => item.activeUsers);
      this.chartOptions.xaxis.categories = sortedData.map(item => this.formatSingleDate(item.date));
      this.updategraph();
    }
  },
  mounted() {
    this.getClient();
    const startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
    const endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
    this.dateRange = [startDate, endDate];
  },
  computed: {
    totalRows() {
      if (this.searchAnalytics.length > 0) {
        return this.filteredActivityLength
      } else {
        return this.sessionCampaignData ? this.sessionCampaignData.length : 0;
      }
    },
    paginatedActivities() {
      if (!Array.isArray(this.sessionCampaignData) || this.sessionCampaignData.length === 0) {
        return [];
      }
      let filteredActivities = this.sessionCampaignData;
      if (this.searchAnalytics) {
        filteredActivities = this.sessionCampaignData.filter(activity => {
          return (activity.sessionCampaignName && activity.sessionCampaignName.toLowerCase().includes(this.searchAnalytics.toLowerCase()));
        });
      }
      const start = (this.currentPage - 1) * this.pageLength;
      const end = start + this.pageLength;
      this.filteredActivityLength = filteredActivities.length
      return filteredActivities.slice(start, end);
    },
    userTypes() {
      let userTypes = this.userTypeData;
      let types = Object.keys(userTypes);
      let values = [];
      for (const type in userTypes) {
        if (Object.hasOwnProperty.call(userTypes, type)) {
          let userTypeData = userTypes[type];
          userTypeData = userTypeData ? userTypeData[0] : null;
          values.push({
            value: userTypeData.value,
            name: userTypeData.dimensionKey,
          });
        }
      }
      return {
        title: types.join(" vs "),
        series: [
          {
            name: "User Types",
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: values,
          },
        ],
        options: {
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)",
          },
          legend: {
            orient: "horizontal",
            left: "center",
          },
        },
      };
    },
    sessionsByCounty() {
      let sessionsData = this.sessionCounties;

      // Validate that sessionsData exists and is an array
      if (!Array.isArray(sessionsData)) {
        return null;
      }

      // Process and validate the data
      let top10countries = sessionsData
        .filter(country => {
          // Filter out invalid entries
          if (!country || !country.country || !country.sessions) {
            return false;
          }
          return true;
        })
        .map(country => ({
          label: country.country, // Use the country name as the label
          value: parseInt(country.sessions, 10) || 0, // Parse sessions as a number
        }))
        .sort((a, b) => b.value - a.value) // Sort by session count in descending order
        .slice(0, 10); // Take the top 10

      // Check if there are any valid entries
      if (top10countries.length === 0) {
        return null;
      }

      // Safely extract top country and session count
      this.topCountry = top10countries[0]?.label || "N/A";
      this.topCountryCount = top10countries[0]?.value || 0;

      // Extract labels and values for the chart
      let labels = top10countries.map(country => country.label);
      let values = top10countries.map(country => country.value);


      // Return the formatted data for the chart
      return {
        labels: labels, // Country names
        datasets: [
          {
            data: values,
            backgroundColor: "#4D21FF",
            borderColor: "transparent",
            barThickness: 15,
          },
        ],
      };
    }
    ,
    sessionsByCity() {
      let cityData = this.userTypeData;

      // Check if cityData exists and is an array
      if (!Array.isArray(cityData)) {
        return null;
      }

      // Filter and process data
      let top10countries = cityData
        .filter(country => {
          // Ensure each entry is valid
          if (!country || !country.date || !country.activeUsers) {
            return false;
          }
          return true;
        })
        .map(country => ({
          label: this.formatSingleDate(country.date), // Format the date
          value: parseInt(country.activeUsers, 10) || 0, // Parse activeUsers as a number
        }))
        .sort((a, b) => b.value - a.value) // Sort by value in descending order
        .slice(0, 10); // Get the top 10

      // Check if top10countries is empty
      if (top10countries.length === 0) {
        return null;
      }

      // Extract top city and count with safe access
      this.topCity = top10countries[0]?.label || "N/A";
      this.topCityCount = top10countries[0]?.value || 0;

      // Extract labels and values for the chart
      let labels = top10countries.map(country => country.label);
      let values = top10countries.map(country => country.value);


      // Return chart data
      return {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: "#4D21FF",
            borderColor: "transparent",
            barThickness: 15,
          },
        ],
      };
    }

    ,
    sessionsByDevice() {
      let sessionsData = this.sessionDevices;

      // Initialize arrays for device types and values
      let devices = [];
      let values = [];

      // Validate that `sessionsData` is an array
      if (!Array.isArray(sessionsData)) {
        return { series: [], options: {} }; // Return default empty chart data
      }

      // Process each device data entry
      sessionsData.forEach(deviceData => {
        if (deviceData?.deviceCategory && deviceData?.sessions) {
          // Capitalize the device type
          let deviceType =
            deviceData.deviceCategory.charAt(0).toUpperCase() +
            deviceData.deviceCategory.slice(1);

          // Parse sessions as a numeric value
          let numericValue = parseInt(deviceData.sessions, 10);

          if (!isNaN(numericValue)) {
            // Add valid data to arrays
            values.push({
              value: numericValue,
              name: deviceType,
            });
            devices.push(deviceType);
          } else {
            console.warn(
              "Invalid sessions value for device:",
              deviceData.deviceCategory,
              deviceData.sessions
            );
          }
        } else {
          console.warn("Missing deviceCategory or sessions:", deviceData);
        }
      });

      // Handle the case where no valid data is available
      if (values.length === 0) {
        this.topDevices = "N/A";
        this.topDevicesCount = 0;
        return { series: [], options: {} };
      }

      // Find the device with the highest session count
      let maxDevice = values.reduce(
        (max, current) => (current.value > max.value ? current : max),
        values[0]
      );

      // Update the top device information
      this.topDevices = maxDevice?.name || "N/A";
      this.topDevicesCount = maxDevice?.value || 0;

      // Return the chart configuration
      return {
        series: [
          {
            name: "Sessions by Device",
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            radius: ["40%", "70%"],
            labelLine: {
              show: false,
            },
            data: values,
          },
        ],
        options: {
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)",
          },
          legend: {
            orient: "horizontal",
            left: "center",
          },
        },
      };
    }

    ,
  },
};
</script>

<style scoped lang="scss">
svg.calendarIcon {
  height: 30px;
  width: 30px;
}


.breadcrumb-container a {
  color: antiquewhite !important;
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.info-container {
  .stats-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: auto;
    margin: 0 auto;
    text-align: center;
  }

  .stat {
    background-color: #fff;
    padding: 10px 10px;
    border-radius: 10px;
    width: 100%;
  }

  .stat h1 {
    font-size: 2.5rem;
    margin: 0;
    color: #333;
  }

  .stat p {
    font-size: 1rem;
    color: #666;
    margin: 10px 0 0;
  }
}

.date-wise-data-card {
  .card-body {
    padding: 0rem !important;
  }
}


.activity-trackor {
  .log-table-container {
    width: 100%;
    padding: 10px 0px;
    font-family: Arial, sans-serif;
  }

  .log-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
  }

  .log-table th,
  .log-table td {
    padding: 16px 10px;
    text-align: left;
  }

  .log-table th {
    font-weight: bold;
    color: #6b7280;
    background-color: #f3f4f6;
  }

  .log-table tbody tr {
    border-bottom: 1px solid #e5e7eb;
  }

  .log-user-info {
    font-weight: 600;
    color: #1d1d1d;
  }

  .log-user-id {
    font-size: 0.9em;
    color: #8c8c8c;
  }

  .log-description {
    color: #6b7280;
    font-size: 0.9em;
  }

  .status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 0.9em;
    text-align: center;
  }

  .status.logout {
    background-color: #eef2ff;
    color: #4f46e5;
  }

  .status.login {
    background-color: #d1fae5;
    color: #059669;
  }

  .log-date {
    color: #1f2937;
    font-weight: 600;
  }

  .currency {
    font-size: 0.9em;
    color: #8c8c8c;
  }
}

.search-activity-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input-search-activity {
    padding: 10px;
    border: 2px solid #EEEEEE;
    border-radius: 10px;
    width: 250px;
  }
}
</style>